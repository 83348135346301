<template>
  <ul class="text-center">
    <li>
      <router-link to="/paginationnr1" class="hover:text-red-600">Example Nr.1</router-link>
    </li>

  </ul>

</template>

<script>
export default {
  name: "Paginations"
}
</script>

<style scoped>

</style>